<script>
export default {
  name: "tasklist2",
  data() {
    return {
      tasklist: [],
    }
  },
  mounted() {
    this.gettasklist()
  },
  methods: {
    contentTurn(content) {
      return content.split("\n").join("<br/>");
    },
    saveclick(item) {
      this.$sa0.post({
        url: this.$api('二级：保存委托任务'),
        data: {
          callback: item.callback,
          id: item.id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.gettasklist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    gettasklist() {
      this.$sa0.post({
        url: this.$api('二级：委托任务列表'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tasklist = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <table class="layui-table" lay-size="sm">
      <thead>
      <tr>
        <th>任务内容</th>
        <th>任务状态</th>
        <th>回执</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,key) in tasklist" :key="key">
        <td v-html="contentTurn(item.content)"></td>
        <td>
          <div v-if="Number(item.status) == 1">待处理</div>
          <div v-if="Number(item.status) == 2">已接收</div>
          <div v-if="Number(item.status) == 3">已完成</div>
        </td>
        <td>
          <a-input v-if="Number(item.status) != 3" v-model="item.callback"></a-input>
          <div v-if="Number(item.status) == 3">
            <a target="_blank" v-if="item.callback" :href="item.callback">{{ item.callback }}</a>
          </div>
        </td>
        <td>
          <a-button @click="saveclick(item)" v-if="Number(item.status) != 3" type="primary">保存</a-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

</style>